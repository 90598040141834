import React, { useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import userpool from "./../userpool";
import { Toast } from "primereact/toast";
import { Slider } from "primereact/slider";
import Markdown from "markdown-to-jsx";
import { Card } from "primereact/card";
import "./CustomCard.css";
import { Message } from "primereact/message";
import Cookies from "universal-cookie";

const textAreaStyles = {
  width: "100%",
  height: "calc(1.5em * 10)", // 1.5em is a rough average line height, multiplied by 10 lines
};

const DevForm = () => {
  const toast = useRef(null);
  const cookies = new Cookies();

  const [selectedModel, setSelectedModel] = useState("gpt-4o-mini");
  const [systemInstruction, setSystemInstruction] = useState(
    cookies.get("systemInstruction")
  );
  const [userRequest, setUserRequest] = useState(cookies.get("userRequest"));
  const [knowledgeContext, setKnowledgeContext] = useState(
    cookies.get("knowledgeContext")
  );
  const [invoicingDraft, setInvoicingDraft] = useState("");
  const [backendRequestRunning, setBackendRequestRunning] = useState(false);
  const [temperature, setTemperature] = useState(1);
  const [topP, setTopP] = useState(1);
  const [httpError, setHttpError] = useState("");

  const modelOptions = [
    { label: "GPT-4o-mini", value: "gpt-4o-mini" },
    { label: "GPT-4o", value: "gpt-4" },
  ];

  function readChunks(reader) {
    return {
      async *[Symbol.asyncIterator]() {
        let readResult = await reader.read();
        while (!readResult.done) {
          yield readResult.value;
          readResult = await reader.read();
        }
      },
    };
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHttpError("");
    let user = userpool.getCurrentUser();

    if (!user) {
      console.log("Login needed");
      toast.current.show({
        severity: "error",
        summary: "Login erforderlich",
        detail: "Bitte loggen Sie sich ein, bevor Sie Anfragen absenden",
      });
      return;
    }

    setBackendRequestRunning(true);
    setInvoicingDraft("");

    cookies.set("systemInstruction", systemInstruction, { path: "/" });
    cookies.set("userRequest", userRequest, { path: "/" });
    cookies.set("knowledgeContext", knowledgeContext, { path: "/" });

    const payload = {
      model: selectedModel,
      prompt: systemInstruction + "\n" + userRequest + "\n" + knowledgeContext,
      temperature: temperature,
      top_p: topP,
      user: user.username,
    };

    const options = {
      method: "POST",
      headers: new Headers({
        // Authorization:
        //   "AWS " +
        //   process.env.REACT_APP_LAMBDA_TOKEN_ID +
        //   ":" +
        //   process.env.REACT_APP_LAMBDA_TOKEN_PW,
        "Content-Type": "application/json",
        Host: process.env.REACT_APP_LAMBDA_STREAMING_URL_PLAIN_INVOCATION,
        // "Access-Control-Request-Method": "POST",
        // "Access-Control-Request-Headers": "X-Auth-Token",
      }),
      body: JSON.stringify(payload),
    };

    try {
      let answer = "";
      var decoder = new TextDecoder();

      // const response = await axios.post(
      //   process.env.REACT_APP_API_URL + "plain_model_invocation",
      //   payload
      // );
      // console.log("Response:", response.data);
      // setInvoicingDraft(response.data.body.result);
      // setWaitingForAnswer(false);

      fetch(
        process.env.REACT_APP_LAMBDA_STREAMING_URL_PLAIN_INVOCATION,
        options
      ).then(async (response) => {
        const reader = response.body.getReader();
        for await (const chunk of readChunks(reader)) {
          const chunk2 = decoder.decode(chunk);
          answer += chunk2;
          setInvoicingDraft(answer);
        }

        // Take out the session id
        // const splitStrings = answer.split("###Session ID:");
        // setInvoicingDraft(splitStrings[0]);
        // const session_id = splitStrings[1].split(";")[0];
        // setSessionId(session_id);
        setBackendRequestRunning(false);
      });
    } catch (err) {
      console.error("Error submitting data:", err);
      setHttpError(err.message);
      setBackendRequestRunning(false);
    }
  };

  return (
    <div style={{ padding: "20px" }} className="main-card main-card-content">
      <Toast ref={toast} />
      <div className="formGrid grid">
        <div className="field col-6 col-offset-3">
          <h2 className="flex">Modell testen</h2>
          <div className="field grid">
            <label htmlFor="ModelSelector" className="col-6">
              Modell
            </label>
            <div className="col-6">
              <Dropdown
                id="ModelSelector"
                value={selectedModel}
                options={modelOptions}
                onChange={(e) => setSelectedModel(e.value)}
                placeholder="Bitte auswählen"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="field grid">
            <label htmlFor="systemInstruction" className="col-12">
              System Instruction
            </label>
            <div className="col-12">
              <InputTextarea
                id="systemInstruction"
                value={systemInstruction}
                onChange={(e) => setSystemInstruction(e.target.value)}
                style={textAreaStyles}
                autoResize={false} // Disable auto resize to maintain height
              />
            </div>
          </div>
          <div className="field grid">
            <label htmlFor="userRequest" className="col-12">
              User Request
            </label>
            <div className="col-12">
              <InputTextarea
                id="userRequest"
                value={userRequest}
                onChange={(e) => setUserRequest(e.target.value)}
                style={textAreaStyles}
                autoResize={false} // Disable auto resize to maintain height
              />
            </div>
          </div>
          <div className="field grid">
            <label htmlFor="knowledgeContext" className="col-12">
              Knowledge Context
            </label>
            <div className="col-12">
              <InputTextarea
                id="knowledgeContext"
                value={knowledgeContext}
                onChange={(e) => setKnowledgeContext(e.target.value)}
                style={textAreaStyles}
                autoResize={false} // Disable auto resize to maintain height
              />
            </div>
          </div>
          <div className="field grid">
            <label htmlFor="temperature" className="col-6">
              Temperatur
            </label>
            <div className="col-6">
              <Slider
                value={temperature}
                onChange={(e) => setTemperature(e.value)}
                step={0.1}
                min={0}
                max={2}
              />
              {temperature}
            </div>
          </div>
          <div className="field grid">
            <label htmlFor="topP" className="col-6">
              Top_P
            </label>
            <div className="col-6">
              <Slider
                value={topP}
                onChange={(e) => setTopP(e.value)}
                step={0.1}
                min={0}
                max={1}
              />
              {topP}
            </div>
          </div>
          <div className="field flex justify-content-end">
            <Button
              label="Anfrage absenden"
              onClick={handleSubmit}
              disabled={
                backendRequestRunning ||
                !systemInstruction ||
                !userRequest ||
                !knowledgeContext
              }
            />
          </div>
          {httpError && (
            <div className="field flex justify-content-start">
              <Message
                severity="error"
                style={{ padding: "0px 10px 0px 10px" }}
                text={<p>Fehler: {httpError}</p>}
              />
            </div>
          )}
        </div>
        {/* {waitingForAnswer && (
          <div className="field col-6 col-offset-3 flex justify-content-center">
            <ProgressSpinner />
          </div>
        )} */}

        {invoicingDraft && (
          <div className="field col-10 col-offset-1">
            <Card
              className="custom-card-body custom-card-title custom-card-content"
              title="Vorschlag für die abzurechnenden Positionen"
            >
              <Markdown>{invoicingDraft}</Markdown>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default DevForm;
