import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import userpool from "./../userpool";
import { Toast } from "primereact/toast";
import Markdown from "markdown-to-jsx";
import { Card } from "primereact/card";
import "./CustomCard.css";
import { Message } from "primereact/message";
import axios from "axios";
import FeedbackModal from "./FeedbackModal";

const textAreaStyles = {
  width: "100%",
  height: "calc(1.5em * 10)", // 1.5em is a rough average line height, multiplied by 10 lines
};

const RequestForm = () => {
  const fetch = require("node-fetch");

  const toast = useRef(null);

  const [selectedProtocol, setSelectedProtocol] = useState("BEMA");
  const [selectedBema, setSelectedBema] = useState("KFO");
  const [treatmentProtocol, setTreatmentProtocol] = useState("");
  const [invoicingDraft, setInvoicingDraft] = useState("");
  const [backendRequestRunning, setBackendRequestRunning] = useState(false);
  const [httpError, setHttpError] = useState("");
  const [session_id, setSessionId] = useState("");
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [version, setVersion] = useState(null);

  useEffect(() => {
    if (!version) {
      const user = userpool.getCurrentUser();
      const referrer = document.referrer;

      axios
        .get(process.env.REACT_APP_API_URL + "get-version", {
          params: {
            destination: "RequestForm",
            entity: user?.username,
            referrer: referrer,
          },
        })
        .then((response) => {
          setVersion(response.version);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const protocolOptions = [
    { label: "BEMA", value: "BEMA" },
    { label: "GOZ (demnächst verfügbar)", value: "GOZ", disabled: true },
    { label: "BEL II (demnächst verfügbar)", value: "BEL II", disabled: true },
    { label: "BEB 97 (demnächst verfügbar)", value: "BEB 97", disabled: true },
    {
      label: "BEB 2009 (demnächst verfügbar)",
      value: "BEB 2009",
      disabled: true,
    },
  ];

  const bemaOptions = [
    { label: "KCH (demnächst verfügbar)", value: "KCH", disabled: true },
    { label: "KBR (demnächst verfügbar)", value: "KBR", disabled: true },
    { label: "KFO", value: "KFO" },
    { label: "PAR (demnächst verfügbar)", value: "PAR", disabled: true },
    { label: "ZE (demnächst verfügbar)", value: "ZE", disabled: true },
    { label: "IP/FU (demnächst verfügbar)", value: "IP/FU", disabled: true },
  ];

  function readChunks(reader) {
    return {
      async *[Symbol.asyncIterator]() {
        let readResult = await reader.read();
        while (!readResult.done) {
          yield readResult.value;
          readResult = await reader.read();
        }
      },
    };
  }

  const handlePositiveFeedback = async (e) => {
    e.preventDefault();
    saveFeedback("positive", "");
  };

  const handleNegativeFeedback = async (e) => {
    e.preventDefault();
    setFeedbackVisible(true);
  };

  async function saveFeedback(rating, notes) {
    setHttpError("");

    if (!session_id) {
      console.log("Session ID missing");
      return;
    }

    const payload = {
      rating: rating,
      notes: notes,
      session_id: session_id,
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "save-feedback",
        JSON.stringify(payload)
      );
      if (response.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Danke für Ihre Feedback!",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Fehler beim Übermitteln Ihres Feedbacks!",
        });
      }
    } catch (err) {
      console.error("Fehler bei der Übermittlung:", err);
      setHttpError(err.message);
    }
  }

  const handleSubmitStreaming = async (e) => {
    e.preventDefault();
    setHttpError("");
    let user = userpool.getCurrentUser();

    if (!user) {
      console.log("Login needed");
      toast.current.show({
        severity: "error",
        summary: "Login erforderlich",
        detail: "Bitte loggen Sie sich ein, bevor Sie Anfragen absenden",
      });
      return;
    }

    setBackendRequestRunning(true);
    setInvoicingDraft("");

    const payload = {
      billing_framework: selectedProtocol,
      part: selectedBema,
      treatment_protocol: treatmentProtocol,
      user: user.username,
    };

    const options = {
      method: "POST",
      headers: new Headers({
        // Authorization:
        //   "AWS " +
        //   process.env.REACT_APP_LAMBDA_TOKEN_ID +
        //   ":" +
        //   process.env.REACT_APP_LAMBDA_TOKEN_PW,
        "Content-Type": "application/json",
        Host: process.env.REACT_APP_LAMBDA_STREAMING_URL,
        // "Access-Control-Request-Method": "POST",
        // "Access-Control-Request-Headers": "X-Auth-Token",
      }),
      body: JSON.stringify(payload),
    };

    try {
      let answer = "";
      var decoder = new TextDecoder();

      fetch(process.env.REACT_APP_LAMBDA_STREAMING_URL, options).then(
        async (response) => {
          const reader = response.body.getReader();
          for await (const chunk of readChunks(reader)) {
            const chunk2 = decoder.decode(chunk);
            answer += chunk2;
            setInvoicingDraft(answer);
          }

          // Take out the session id
          const splitStrings = answer.split("###Session ID:");
          setInvoicingDraft(splitStrings[0]);
          const session_id = splitStrings[1].split(";")[0];
          setSessionId(session_id);
          setBackendRequestRunning(false);
        }
      );
    } catch (err) {
      console.error("Error submitting data:", err);
      setHttpError(err.message);
      setBackendRequestRunning(false);
    }
  };

  return (
    <div style={{ padding: "20px" }} className="main-card main-card-content">
      <Toast ref={toast} />
      <div className="formGrid grid">
        <div className="field col-6 col-offset-3">
          {/* <h2 className="flex">Abrechenbare Leistungen ermitteln</h2> */}
          <div className="field grid">
            <label htmlFor="protocolSelector" className="col-6">
              Abrechnungsrahmen:
            </label>
            <div className="col-6">
              <Dropdown
                id="protocolSelector"
                value={selectedProtocol}
                options={protocolOptions}
                onChange={(e) => setSelectedProtocol(e.value)}
                placeholder="Bitte auswählen"
                style={{ width: "100%" }}
                scrollHeight="400px"
                optionDisabled="disabled"
              />
            </div>
          </div>
          <div className="field grid">
            <label htmlFor="bemaPart" className="col-6">
              Behandlungskategorie:
            </label>
            <div className="col-6">
              <Dropdown
                id="bemaPart"
                value={selectedBema}
                options={bemaOptions}
                onChange={(e) => setSelectedBema(e.value)}
                placeholder="Bitte auswählen"
                style={{ width: "100%" }}
                scrollHeight="400px"
                optionDisabled="disabled"
              />
            </div>
          </div>
          <div className="field grid">
            <label htmlFor="treatmentProtocol" className="col-12">
              Ihre Anfrage:
            </label>
            <div className="col-12">
              <InputTextarea
                id="treatmentProtocol"
                value={treatmentProtocol}
                onChange={(e) => setTreatmentProtocol(e.target.value)}
                style={textAreaStyles}
                autoResize={false} // Disable auto resize to maintain height
              />
            </div>
          </div>
          <div className="field flex justify-content-end">
            <Button
              label="Anfrage absenden"
              onClick={handleSubmitStreaming}
              disabled={
                backendRequestRunning ||
                !selectedProtocol ||
                !selectedBema ||
                !treatmentProtocol
              }
            />
          </div>
          {httpError && (
            <div className="field flex justify-content-start">
              <Message
                severity="error"
                style={{ padding: "0px 10px 0px 10px" }}
                text={<p>Fehler: {httpError}</p>}
              />
            </div>
          )}
        </div>
        {(invoicingDraft || backendRequestRunning) && (
          <div className="field col-6 col-offset-3 flex align-items-end justify-content-end">
            Wie bewerten Sie das Ergebnis?
            <Button
              icon="pi pi-thumbs-up"
              severity="success"
              aria-label="Search"
              style={{ marginLeft: "10px", marginRight: "10px" }}
              onClick={handlePositiveFeedback}
            />
            <Button
              icon="pi pi-thumbs-down"
              severity="warning"
              aria-label="Notification"
              style={{ marginLeft: "10px", marginRight: "10px" }}
              onClick={handleNegativeFeedback}
            />
          </div>
        )}

        {(invoicingDraft || backendRequestRunning) && (
          <div className="field col-10 col-offset-1">
            <Card
              className="custom-card-body custom-card-title custom-card-content"
              title="Antwort zu Ihrer Anfrage"
            >
              <Markdown>{invoicingDraft}</Markdown>
            </Card>
          </div>
        )}
      </div>
      <FeedbackModal
        visible={feedbackVisible}
        setVisible={setFeedbackVisible}
        saveFeedback={saveFeedback}
      />
    </div>
  );
};

export default RequestForm;
